import React from 'react';
import Section from './Section';

export default function Projects() {
  return (
    <Section>
      <h2>Projects</h2>
      <p>
        Under construction...
      </p>
    </Section>
  );
}
